import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useMediaQuery} from "react-responsive";
import {useLocation} from "react-router-dom";
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import {css} from '@emotion/react';
import '../../App.css';

import {scrollIconChange, scrollPositionChange} from "../../reducers";

import scrollDownImg from '../common/dropdown.svg';
import logo1 from './img/logo-asset-1.svg';
import logo2 from './img/logo-asset-2.svg';
import logo3 from './img/logo-asset-3.svg';
import logo4 from './img/logo-asset-4.svg';
import logo5 from './img/logo-asset-5.svg';
import logo6 from './img/logo-asset-6.svg';
import logo7 from './img/logo-asset-7.svg';
import logo8 from './img/logo-asset-8.svg';
import logo9 from './img/logo-asset-9.svg';
import logo10 from './img/logo-asset-10.svg';
import scrollTop from './img/scroll.svg';
import scrollbar_1 from './img/scrollbar_1.svg';
import scrollbar_2 from './img/scrollbar_2.svg';
import scrollbar_3 from './img/scrollbar_3.svg';
import scrollbar_4 from './img/scrollbar_4.svg';
import dropDownImg from "../common/dropdown.svg";

import aboutContents from './about_contents.json';
import Multilingual from "multilingual.js";

const Section = styled.div`
  display: flex;
  & > div {
    flex: 1;
    flex-basis: 50%;
    max-width: 50%;
  }
  @media (max-width: 768px) {
    display: block;
    & > div {
      max-width: unset;
    }
  }
`;
const ContentTitle = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  & h1 {
    font-style: italic;
    text-align: center;
  }
  & span {
    line-height: 0.85;
  }
  & .pretendard {
    display: block;
    margin-top: 4rem;
  }
  @media (max-width: 768px) {
    height: 60vh;
    width: 100%;
    left: 0;
    background: linear-gradient(rgba(240, 240, 235, 1) 80%, rgba(240, 240, 235, 0));
    & h1 {
      margin-top: 4rem;
    }
    & .pretendard {
      margin-top: 1rem;
    }
  }
`;
const Contents = styled.div`
  margin-top: 32.5vh;
  margin-bottom: 3rem;
  & p {
    text-indent: 1.2em;
  }
  & .paragraph {
    margin-bottom: .95rem!important;
  }
  & .en-font2-d {
    line-height: 1.35;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  & .textIndent0 {
    text-indent: 0;
  }
  @media (max-width: 768px) {
    min-height: 36vh;
    margin-top: 50vh;
    & p {
      padding: 0 1rem;
    }
    & .paragraph {
      margin-bottom: .78rem!important;
    }
  }
`;
const relative = css`
  position: relative;
  padding-top: 7rem;
  margin-top: 100vh;
  max-width: 100% !important;
  overflow: hidden;
  z-index: 10;
  & img {
    transform-origin: center;
    width: 100%;
  }
  @media (min-width: 769px) {
    margin-left: -50%;
    & img {
      padding-top: 5rem;
    }
  }
  @media (max-width: 768px) {
    margin-top: 30vh;
  }
`;
const mt0 = css`
  @media (min-width: 769px) {
    margin-top: -110px;
  }
`;
const ScrollBar = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
  position: fixed;
  top: 0;
  left: 63%;
  z-index: 100;
  height: 100vh;
  & .scroll {
    width: 50px;
    height: 50px;
  }
  & .scroll.Fixed {
    position: relative;
    top: 1rem;
  }
  & .scroll.Bar {
    margin-top: -0.5rem;
  }
  & .scroll.Bar img {
    position: absolute;
    bottom: 1rem;
  }
`;
const logo = css`
  position: fixed;
  top: 15%;
`;

export default function About() {
    const [contents, setContents] = useState({
        begin: {kr: [], en: []},
        people: {kr: [], en: []},
        with: {kr: [], en: [], positionsKr: '', positionsEn: '', detailKr: '', detailEn: '', contact: ''}
    });
    const [loaded, setLoaded] = useState(false);

    const isMobile = useMediaQuery({query: '(max-width: 768px)'})
    const location = useLocation();

    const beginRef = useRef(null);
    const introRef = useRef(null);
    const peopleRef = useRef(null);
    const withRef = useRef(null);
    const logoRef = useRef(null);
    const wholeRef = useRef(null);
    const krRef = useRef([]);

    const icons = [scrollbar_1, scrollbar_2, scrollbar_3, scrollbar_4];

    const dispatch = useDispatch();
    const {scroll} = useSelector(state => state);

    const [position, setPosition] = useState(0);
    useEffect(() => {
        fetch('https://unofficial-office.com/about-content.json')
            .then(response => response.json())
            .then(data => {
                setContents(data);
            }).then(() => setLoaded(true));
        dispatch(scrollIconChange(detectSection()));
        dispatch(scrollPositionChange(detectPosition()));
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    useEffect(() => {
        if (location.hash === '#begin') {
            window.scrollTo({
                top: beginRef.current.offsetTop,
                left: 0,
                behavior: "smooth",
            });
        } else if (location.hash === '#people') {
            window.scrollTo({
                top: peopleRef.current.offsetTop,
                left: 0,
                behavior: "smooth",
            });
        } else if (location.hash === '#with') {
            window.scrollTo({
                top: withRef.current.offsetTop,
                left: 0,
                behavior: "smooth",
            });
        }
    }, [location]);

    useEffect(() => {
        if (loaded) {
            let multilingual = new Multilingual({
                containers: krRef.current,
                configuration: ['en', 'num', 'punct']
            });
        }
    }, [loaded]);

    const onScroll = () => {
        dispatch(scrollIconChange(detectSection()));
        dispatch(scrollPositionChange(detectPosition()));
        setPosition(window.scrollY);
    };
    const logos = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10];
    const degrees = ['rotate(30deg)', 'rotate(-5deg)', 'rotate(10deg)', 'rotate(-30deg)', 'rotate(-10deg)',
        'rotate(5deg)', 'rotate(-10deg)', 'rotate(30deg)', 'rotate(40deg)', 'rotate(-25deg)',];

    const calcScrollPosition = (ref) => {
        const pect = (1 - (ref.current.getBoundingClientRect().bottom - window.innerHeight) / ref.current.getBoundingClientRect().height);
        return pect;
    }
    const calcScrollPositionIntro = (ref) => {
        const offset = isMobile ? 110 : 0;
        const pect = Math.abs((ref.current.getBoundingClientRect().top - offset) / (ref.current.getBoundingClientRect().height - window.innerHeight));
        return pect;
    }

    const detectSection = () => {
        if (introRef.current.getBoundingClientRect().bottom >= window.innerHeight) {
            return 0;
        } else if (beginRef.current.getBoundingClientRect().top <= window.innerHeight
            && beginRef.current.getBoundingClientRect().bottom >= window.innerHeight) {
            return 1;
        } else if (peopleRef.current.getBoundingClientRect().top <= window.innerHeight
            && peopleRef.current.getBoundingClientRect().bottom >= window.innerHeight) {
            return 2;
        } else if (withRef.current.getBoundingClientRect().top <= window.innerHeight) {
            return 3;
        }
    }

    const detectPosition = () => {
        if (introRef.current.getBoundingClientRect().bottom >= window.innerHeight) {
            return calcScrollPositionIntro(introRef);
        } else if (beginRef.current.getBoundingClientRect().top <= window.innerHeight
            && beginRef.current.getBoundingClientRect().bottom >= window.innerHeight) {
            return calcScrollPosition(beginRef);
        } else if (peopleRef.current.getBoundingClientRect().top <= window.innerHeight
            && peopleRef.current.getBoundingClientRect().bottom >= window.innerHeight) {
            return calcScrollPosition(peopleRef);
        } else if (withRef.current.getBoundingClientRect().top <= window.innerHeight) {
            return calcScrollPosition(withRef);
        }
    }

    return (
        <div ref={wholeRef}>
            <ScrollBar>
                <div className="scroll Fixed">
                    <img src={scrollTop} alt="scrolltop"/>
                </div>
                <div className="scroll Bar">
                    <img src={icons[scroll ? scroll.icon : 0]}
                         alt="scrollicon1"
                         style={{bottom: (window.innerHeight - 100) * scroll.position}}/>
                </div>
            </ScrollBar>
            <Section className='intro' css={mt0} ref={introRef}>
                <ContentTitle>
                    <h1>
                        <span className="en-font1">OFFICIA</span>
                        <span className="en-font1 swash">L</span>
                        <br/>
                        <span className="en-font1 swash">T</span>
                        <span className="en-font1">HINGS</span>
                        <br/>
                        <span className="en-font1 swash">A</span>
                        <span className="en-font1">RE</span>
                        <br/>
                        <span className="en-font1 swash">N</span>
                        <span className="en-font1">O</span>
                        <span className="en-font1 swash">F</span>
                        <span className="en-font1">UN</span>
                    </h1>
                </ContentTitle>
                {
                    beginRef.current && beginRef.current.getBoundingClientRect().top > window.innerHeight - 10 && isMobile
                    && <div className="scrollDownAni" style={{width: '100%', position: 'fixed', left: 'calc(50% - 35px)', top: '70vh', zIndex: '-1', height: '30px'}}><img src={scrollDownImg} alt="scrolldown" style={{width:'70px'}}/></div>
                }
                <Contents className='logos' css={relative} style={{minHeight: '300vh'}} ref={logoRef}>
                    {
                        logoRef.current && logoRef.current.getBoundingClientRect().top < window.innerHeight &&
                        beginRef.current.getBoundingClientRect().top > window.innerHeight &&
                        <img css={logo}
                             src={logos[parseInt(position / (0.4 * window.innerHeight))]}
                             style={{
                                 transform: `${isMobile ? 'scale(0.8)' : 'scale(0.6)'} ${degrees[parseInt(position / (0.4 * window.innerHeight))]}`,
                                 top: isMobile ? '60%' : '5%',
                                 left: isMobile ? '0%' : '18%',
                             }}
                             alt='logo'/>
                    }
                </Contents>
            </Section>
            <Section className='begin' id='begin' ref={beginRef}>
                <ContentTitle>
                    <h1>
                        <span className="en-font1 m-en-font1 swash">B</span>
                        <span className="en-font1 m-en-font1">EGIN</span>
                        <span className="en-font1 m-en-font1 swash">S</span>

                        <span className="kr-font1 m-kr-font1 pretendard spacing1">시작</span>
                    </h1>
                </ContentTitle>
                <Contents>
                    {/*begin kr 영역*/}
                    <>
                        {
                            contents.begin.kr.map(
                                (text, index) => (
                                    <p className={`about-kr kr-font2-d paragraph ${index === 0 ? 'textIndent0' : ''}`}
                                       key={index}
                                       ref={(elem) => krRef.current.push(elem)}>
                                        {text}
                                    </p>
                                )
                            )
                        }
                    </>
                    {/*begin en 영역*/}
                    <>
                        {
                            contents.begin.en.map(
                                (text, index) => (
                                    <p className={`en-font2-d paragraph ${index === 0 ? 'textIndent0' : ''}`} key={index}>{text}</p>
                                )
                            )
                        }
                    </>
                </Contents>
            </Section>
            <Section className='people' id='people' ref={peopleRef}>
                <ContentTitle>
                    <h1>
                        <span className="en-font1 m-en-font1 swash">PE</span>
                        <span className="en-font1 m-en-font1">OP</span>
                        <span className="en-font1 m-en-font1 swash">LE</span>

                        <span className="kr-font1 m-kr-font1 pretendard spacing1">사람들</span>
                    </h1>
                </ContentTitle>
                <Contents>
                    {/*people kr 영역*/}
                    <>
                        {
                            contents.people.kr.map(
                                (text, index) => (
                                    <p className={`about-kr kr-font2-d paragraph ${index === 0 ? 'textIndent0' : ''}`}
                                       key={index}
                                       ref={(elem) => krRef.current.push(elem)}>
                                        {text}
                                    </p>
                                )
                            )
                        }
                    </>
                    {/*people en 영역*/}
                    <>
                        {
                            contents.people.en.map(
                                (text, index) => (
                                    <p className={`en-font2-d paragraph ${index === 0 ? 'textIndent0' : ''}`} key={index}>{text}</p>
                                )
                            )
                        }
                    </>
                </Contents>
            </Section>
            <Section className='with' id='with' ref={withRef}>
                <ContentTitle>
                    <h1>
                        <span className="en-font1 m-en-font1">WI</span>
                        <span className="en-font1 m-en-font1 swash">TH</span>

                        <span className="kr-font1 m-kr-font1 pretendard">함께하는</span>
                    </h1>
                </ContentTitle>
                <Contents>
                    {/*with kr 영역*/}
                    <>
                        {
                            contents.with.kr.map(
                                (text, index) => (
                                    <p className={`about-kr kr-font2-d paragraph ${index === 0 ? 'textIndent0' : ''}`}
                                       key={index}
                                       ref={(elem) => krRef.current.push(elem)}>
                                        {text}
                                    </p>
                                )
                            )
                        }
                    </>
                    <p className="kr-font2-d paragraph textIndent0">지원분야{' '}</p>
                    <p className="kr-font2-d">
                        {contents.with.positionsKr}
                    </p>
                    <p className="kr-font2-d">
                        {contents.with.detailKr}
                    </p>
                    <p className="en-font2-d BigCaslonItalic paragraph textIndent0">{contents.with.contact}</p>
                    {/*with en 영역*/}
                    <>
                        {
                            contents.with.en.map(
                                (text, index) => (
                                    <p className={`en-font2-d paragraph ${index === 0 ? 'textIndent0' : ''}`} key={index}>{text}</p>
                                )
                            )
                        }
                    </>
                    <p className="en-font2-d paragraph textIndent0">Areas</p>
                    <p className="en-font2-d">
                        {contents.with.positionsEn}
                    </p>
                    <p className="en-font2-d">
                        {contents.with.detailEn}
                    </p>
                    <p className="en-font2-d BigCaslonItalic paragraph textIndent0">{contents.with.contact}</p>
                </Contents>
            </Section>
        </div>
    );
}
