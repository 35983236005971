import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Vimeo from '@u-wave/react-vimeo';
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {Navigation, A11y} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Mousewheel, Pagination} from "swiper";

import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/pagination";
import {scrollPositionChange, workPositionChange, workSetSwiper} from "../../reducers";
import {useMediaQuery} from "react-responsive";

import Multilingual from "multilingual.js";

const Contents = styled.div`
  display: flex;
  margin-top: 1.2rem;
  margin-bottom: 7rem;
  @media (max-width: 768px) {
    flex-flow: column;
    padding: 0 1rem;
  }
`;
const Title = styled.h1`
  flex: 2;
  letter-spacing: -0.02em;
  position: relative;
  word-break: keep-all;
  & span {
    display: block;
    line-height: 1.3!important;
  }
  & .swash,
  & .eng {
    font-style: italic;
  }
  @media (max-width: 768px) {
    padding-right: 50px;
    position: relative;
    & p {
      margin-right: 0.5rem;
    }
  }
`;
const DetailToggle = styled.div`
  @media (max-width: 768px) {
    display: block;
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 0;
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: #000;
      position: absolute;
      top: calc(50% - 1px);
    }
    &:before {
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      background: #000;
      position: absolute;
      left: calc(50% - 1px);
    }
    @media (max-width: 768px) {
      &:after {
        height: 1.6px;
        top: calc(50% - 0.6px);
      }
      &:before {
        width: 1.6px;
      }
    }
  }
`;
const Detail = styled.div`
  flex: 4;
  display: flex;
  & > div {
    flex: 3;
    overflow-y: scroll;
  }
  & > div .en-font3 {
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  @media (max-width: 768px) {
    flex-flow: column;
    margin-top: 1rem;
    & > div {
      max-height: unset;
      overflow: unset;
    }
  }
`;
const credits = css`
  flex: 1;
  padding: 0 1rem;
  & li {
    font-size: 1.15em;
    line-height: 1.4;
    margin-bottom: 4px;
  }
  & li p {
    font-style: italic;
    text-decoration-line: underline;
  }
  & li span {
    font-style: normal;
  }
  @media (max-width: 768px) {
    padding: 0;
    margin-top: 1rem;
    order: 3;
    height: 0;
    & li {
      display: flex;
      border-top: 1.6px solid #000;
    }
    & li p {
      text-decoration-line: none;
      flex: 1;
    }
    & li span {
      flex: 1;
    }
  }
`;
const detailToggleBtn = css`
  @media (max-width: 768px) {
    .show-detail {
      transition: 1s;
      max-height: 50vh;
      overflow-y: scroll;
      opacity: 1;
    }

    .hide-detail {
      transition: 1s;
      max-height: 0vh;
      opacity: 0;
      cursor: default;
      overflow: hidden;
    }
  }
`;
export default function Work() {
    const isMobile = useMediaQuery({query: '(max-width: 768px)'})
    const [swiper, setSwiper] = useState(null);
    const workRef = useRef([]);
    const playerRef = useRef([]);

    const {work} = useSelector(state => state);
    const dispatch = useDispatch();

    useEffect(() => {
        if (swiper) dispatch(workSetSwiper(swiper));
    }, [swiper]);

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    const onScroll = () => {
        dispatch(scrollPositionChange(detectPosition()));
        if (isMobile) {
            let workPositon = null;
            workRef.current.forEach((elem, index) => {
                if (elem.getBoundingClientRect().top <= window.innerHeight / 2) {
                    workPositon = index;
                }
            });
            playerRef.current[workPositon].play();
            dispatch(workPositionChange(workPositon));
        }
    };

    const detectPosition = () => {
        return (window.scrollY) / (document.body.scrollHeight - window.innerHeight);
    }

    const handleScroll = event => {
        if (
            (swiper.activeIndex !== work.length - 1
                && event.currentTarget.offsetHeight + event.currentTarget.scrollTop >= event.currentTarget.scrollHeight - 1)
            ||
            (swiper.activeIndex !== 0 && event.currentTarget.scrollTop === 0)
        ) {
            swiper.mousewheel.enable();
            return;
        }
        swiper.mousewheel.disable();
    };

    return (
        <div style={{height: isMobile ? 'calc(100vh - 150px)' : 'calc(100vh - 30px)'}}>
            {!isMobile &&
                <Swiper
                    className='work-slide'
                    direction={"vertical"}
                    onSwiper={setSwiper}
                    mousewheel={false}
                    modules={[Mousewheel]}
                    onActiveIndexChange={({activeIndex}) => {
                        dispatch(workPositionChange(activeIndex));
                        swiper.mousewheel.disable();
                        workRef.current[activeIndex].scrollTop = activeIndex !== 0 ? 20 : 0;
                        playerRef.current[activeIndex].play();
                    }}
                    scrollbar={{draggable: false}}
                    style={{minHeight: '100%'}}
                >
                    {work.works.map((work, index) => {
                        return (
                            <SwiperSlide key={index}
                                         style={{
                                             overflowY: 'scroll'
                                         }}>
                                {({isActive}) =>
                                    (<div className='work-wrapper'
                                          key={index}
                                          onScroll={(e) => {
                                              index === swiper.activeIndex && handleScroll(e);
                                          }}
                                          ref={(elem) => {
                                              workRef.current[index] = elem
                                          }}
                                          style={{
                                              minHeight: '100%',
                                              overflowY: 'scroll'
                                          }}>
                                        {
                                            index !== 0 && <div style={{height: 20}}/>
                                        }
                                        <WorkCard work={work} playerRef={playerRef} play={isActive} workIndex={index}/>
                                        <div style={{height: 100}}/>
                                    </div>)
                                }
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            }
            {
                isMobile &&
                <>
                    {work.works.map((workDetail, index) => {
                        return (
                            <div id={`work-${index}`}
                                 key={index}
                                 ref={(elem) => workRef.current[index] = elem}
                                 style={{minHeight: 'fit-content'}}>
                                <WorkCard work={workDetail} play={index === work.position} playerRef={playerRef}
                                          workIndex={index}/>
                            </div>)
                    })}
                </>
            }
            {
                isMobile && <div style={{height: 80}}/>
            }
        </div>
    );
}

const WorkCard = ({work, playerRef, workIndex, play}) => {
    const [open, setOpen] = useState(false);
    const [swiper, setSwiper] = useState(null);

    const titleRef = useRef([]);

    useEffect(() => {
        let multilingual = new Multilingual({
            containers: titleRef.current,
            configuration: ['en', 'ko', 'num', 'punct', {className: 'ml-space', charset: '( )'}]
        });
    }, []);

    return (
        <>
            <Swiper className='video-swiper scroll-target'
                    mousewheel={false}
                    allowTouchMove={false}
                    modules={[Pagination, Navigation, A11y, Mousewheel]}
                    spaceBetween={20}
                    slidesPerView={1}
                    preventClicks={false}
                    preventClicksPropagation={false}
                    pagination={{
                        clickable: true,
                    }}
                    // loop={true}
                    navigation={{clickable: true}}
                    onSwiper={setSwiper}>
                {
                    work.visualContents.map((content, index) => {
                        return (
                            <SwiperSlide key={index}>
                                {({isActive}) => (
                                    isActive && content.video ? <Vimeo video={content.id}
                                                       muted={true}
                                                       loop={false}
                                                       responsive={true}
                                                       onEnd={(e) => swiper.slideNext()}
                                                       onReady={player => {
                                                           playerRef.current[workIndex] = player;
                                                           if (play) player.play();
                                                       }}/> : <img src={`http://drive.google.com/uc?export=view&id=${content.id}`} alt="img" />)}
                            </SwiperSlide>
                        );
                    })
                }
            </Swiper>
            <Contents className='scroll-target' css={detailToggleBtn}>
                <Title className='work-title'>
                    <p className="en-font2 swash">{work.year}</p>
                    {
                        work.title.map((text, index) =>
                            (<p key={index} ref={(elem) => titleRef.current[index] = elem}>{text}</p>)
                        )
                    }
                    {/*{work.title}*/}
                    <DetailToggle onClick={(e) => {
                        setOpen(!open)
                    }}/>
                </Title>
                <Detail className={open ? 'show-detail' : 'hide-detail'}>
                    <ul css={credits}>
                        <li>
                            <p>Client.</p>
                            <span>{work.credit.subClientKr}<br/>{work.credit.subClientEn}</span>
                        </li>
                        <li>
                            <p>Year.</p>
                            <span>{work.credit.subYear}</span>
                        </li>
                        <li>
                            <p>Media.</p>
                            <span>{work.credit.subMedia}</span>
                        </li>
                    </ul>
                    <div>
                        <p className="kr-font3 m-kr-font3">
                            {
                                work.detailKr.map((text, index) => (<p className='paragraph' key={index}>{text}</p>))
                            }
                        </p>
                        <p className="en-font3 m-en-font3">
                            {
                                work.detailEn.map((text, index) => (<p className='paragraph' key={index}>{text}</p>))
                            }
                        </p>
                    </div>
                </Detail>
            </Contents>
        </>
    );
};
