import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {Route, Routes} from "react-router-dom";

import About from './components/page/About';
import Contact from './components/page/Contact';
import Home from './components/page/Home';
import Layout from "./components/common/Layout";
import Work from './components/page/Work';

import {workSetWorks} from "./reducers";

import './App.css';

function App() {
    const dispatch = useDispatch();
    useEffect(() => {
        fetch('https://unofficial-office.com/work-content.json')
            .then(response => response.json())
            .then(data => {
                dispatch(workSetWorks(data));
            });
    }, []);


    return (
        <div className="App">
            <Routes>
                <Route element={<Layout/>}>
                    <Route path='/' element={<Home/>}/>
                    <Route path='/about' element={<About/>}/>
                    <Route path='/contact' element={<Contact/>}/>
                    <Route path='/work' element={<Work/>}/>
                </Route>
            </Routes>
        </div>
    );
}

export default App;
