import { useSelector } from "react-redux";
import {NavLink} from 'react-router-dom';
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import home from './home.svg';
import Menu from './Menu';

import scrollbar_1 from '../page/img/mobileScrollbar_1.svg';
import scrollbar_2 from '../page/img/mobileScrollbar_2.svg';
import scrollbar_3 from '../page/img/mobileScrollbar_3.svg';
import scrollbar_4 from '../page/img/mobileScrollbar_4.svg';
import {isMobile} from "react-device-detect";

const Logo = styled.h1`
  position: fixed;
  height: 70px;
  padding: 1rem 0.5rem 0;
  z-index: 10;
  & img {
    height: 4vw;
  }
  @media (max-width: 768px) {
    position: relative;
    height: 40px;
    padding: 1rem 1rem 0;
    border-bottom: 1px solid;
    background: #f0f0eb;
    & img {
      height: 40px;
    }
  }
`;

const Divider = styled.div`
  position: fixed;
  width: 2px;
  height: calc(100vh - 2rem);
  background: #f0f0eb;
  top: 0;
  left: calc(33% - 1rem);
  margin: 1rem 0;
  @media (max-width: 768px) {
    display: none;
  }
`;
const ScrollMobile = styled.div`
  position: fixed;
  right: 0;
  top: 0;

  & div {
    position: relative;
    height: 57px;
    width: 57px;
  }
  & img {
    position: absolute;
    bottom: 0;
  }
  @media (min-width: 769px) {
    display: none;
  }
`;

export default function Header() {
  const {scroll} = useSelector(state => state);
  const icons = [scrollbar_1, scrollbar_2, scrollbar_3, scrollbar_4];
  return (
    <>
      <header>
        <Logo>
          <NavLink to='/'>
            <img src={home} alt="logo" />
          </NavLink>
          <ScrollMobile style={{right: (window.innerWidth - 110) * scroll.position}}>
            <div>
              <img src={icons[scroll ? scroll.icon : 0]} alt="" />
            </div>
          </ScrollMobile>
        </Logo>
        <Menu />
        <Divider />
      </header>
    </>
  );
}
