import {Outlet, useLocation} from 'react-router-dom';
import {useMediaQuery} from "react-responsive";

import Header from './Header';

const Layout = () => {
    const {pathname} = useLocation();
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

    return (<>
            {
                pathname !== '/'
                ? <div className="container">
                    {pathname !== '/' && <Header/>}
                    <section style={{
                        padding: pathname === '/work'
                            ? isMobile ? '150px 0 5rem' : '30px 1rem 0'
                            : ''
                    }}>
                        <Outlet/>
                    </section>
                </div>
                : <div className="container">
                        <Outlet/>
                </div>
        }
        </>
    );
};

export default Layout;
