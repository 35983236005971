import {combineReducers} from "redux";

// 스크롤 리듀서
const SCROLLICONCHANGE = "SCROLL/ICONCHANGE";
export const scrollIconChange = icon => ({type: SCROLLICONCHANGE, icon});
const SCROLLPOSITIONCHANGE = "SCROLL/POSITIONCHANGE";
export const scrollPositionChange = position => ({type: SCROLLPOSITIONCHANGE, position});

const initialStateScroll = {
    icon: 0,
    position: 0,
};

const scroll = (state = initialStateScroll, action) => {
    switch (action.type) {
        case SCROLLPOSITIONCHANGE:
            return {
                ...state,
                position: action.position
            };
        case SCROLLICONCHANGE:
            return {
                ...state,
                icon: action.icon
            };
        default:
            return state;
    }
}

// 워크 리듀서
const WORKPOSITIONCHANGE = "WORK/POSITIONCHANGE";
export const workPositionChange = position => ({type: WORKPOSITIONCHANGE, position});
const WORKSETWORKS = "WORK/SETWORKS";
export const workSetWorks = works => ({type: WORKSETWORKS, works});
const WORKSETSWIPER = "WORK/SETSWIPER";
export const workSetSwiper = swiper => ({type: WORKSETSWIPER, swiper});

const initialStateWorks = {
    position: 0,
    swiper: null,
    works: [],
}

const work = (state=initialStateWorks, action) => {
    switch (action.type) {
        case WORKPOSITIONCHANGE:
            return {
                ...state,
                position: action.position
            };
        case WORKSETWORKS:
            return {
                ...state,
                works: action.works
            }
        case WORKSETSWIPER:
            return {
                ...state,
                swiper: action.swiper
            }
        default:
            return state
    }
}

const rootReducer = combineReducers({
    scroll,
    work
});

export default rootReducer;