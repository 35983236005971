import React, {useState} from 'react';
import {useMediaQuery} from "react-responsive";
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
/** @jsxImportSource @emotion/react */
import WorkIndex from '../page/WorkIndex';
import styled from '@emotion/styled';
import {css} from '@emotion/react';
import './header.css';
import '../../App.css';
import dropDownImg from './dropdown.svg';

const Nav = styled.nav`
  padding-top: 110px;
  padding-bottom: 2rem;
  width: 100%;
  & li {
    margin-bottom: 0.5rem;
    position: relative;
  }
  & span {
    cursor: pointer;
    font-family: big-caslon-fb, serif;
    font-style: italic;
    font-size: var(--en-font1);
    letter-spacing: -0.04em;
  }
  @media (max-width: 768px) {
    margin-top: 0;
    width: 100%;
    position: relative;
    z-index: 10;
    padding: 0;
    & ul {
      padding: 0 1rem;
      background: rgba(240, 240, 235, 1);
    }
    & span {
      font-size: var(--m-en-font2);
      line-height: 47px;
    }
  }
`;

const workIndex = css`
  & ul {
    overflow: hidden;
  }
    & .show {
      max-height: 50vh;
      overflow-y: scroll;
      transition: .5s;
    }
    & .hide {
      max-height: 0;
      transition: .5s;
    }
  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    z-index: 10;
    & ul {
      padding: 0 0 40px;
      background-color: #F0F0EB;
      position: relative;
    }
    & ul li:after {
      content: '';
      display: block;
      width: 3rem;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-color: transparent;
    }
    & .show {
      max-height: 50vh;
      overflow-y: scroll;
      transition: .5s;
    }
    & .hide {
      max-height: 0;
      transition: .5s;
    }
  }
`;
const ToggleIcon = styled.div`
  width: 6vw;
  height: 6vw;
  display: flex;
  align-item: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;

  &:after {
    content: '';
    display: block;
    width: 2px;
    height: 6vw;
    background: #000;
    transition: 0.3s;
  }

  &:before {
    content: '';
    display: block;
    width: 6vw;
    height: 2px;
    background: #000;
    position: absolute;
    left: calc(50% - 3vw);
    top: calc(50% - 1px);
  }

  .show-menu &:after {
    transform: rotate(90deg);
  }

  & img {
    display: none;
  }

  @media (max-width: 768px) {
    top: 0;
    right: 0;
    width: 100%;
    height: 47px;
    &:after {
      display: none;
    }

    &:before {
      display: none
    }

    & img {
      display: block;
      width: 30px;
      height: 15px;
      position: absolute;
      right: 0.5rem;
      top: 16px;
    }
  }
`;
const toggle = css`
  .show-menu {
    & ul {
      padding: 1rem 0;
      background: #F0F0EB;
      transition: 1s;
    }
    @media (max-width: 768px) {
      & ul {
        padding: 0 0 3rem;
      }
      & li {
        padding: 0.25rem 1rem;
      }
    }
  }

  .hide-menu {
    & ul {
      max-height: 0;
      overflow: hidden;
      transition: 1s;
    }
    @media (max-width: 768px) {
      & ul {
        padding: 0;
      }
    }
  }
`;

export default function Menu() {
    const isMobile = useMediaQuery({query: '(max-width: 768px)'})

    return (
        <>
            {isMobile && <MenuMobile/>}
            {!isMobile && <MenuPc/>}
        </>
    );
}

const MenuPc = () => {
    const {pathname} = useLocation();
    const [isOpen, setIsOpen] = useState(pathname === '/work');
    const navigate = useNavigate();

    return (
        <>
            <Nav>
                <ul>
                    <li className="mainColor1" css={toggle}>
                        <NavLink to="/work">
                            <span className="en-font1 m-en-font1">Wor</span>
                            <span className="en-font1 m-en-font1 swash">k</span>
                        </NavLink>

                        <div css={workIndex} className={(pathname === '/work' && isOpen) ? 'show-menu' : 'hide-menu'}>
                            <ToggleIcon onClick={(e) => {
                                if (pathname !== '/work') {
                                    navigate('/work');
                                    setIsOpen(true);
                                    return;
                                }
                                setIsOpen(!isOpen);
                            }}/>
                            <WorkIndex/>
                        </div>
                    </li>
                    <li className="mainColor2">
                        <NavLink to="/about">
                            <span className="en-font1 m-en-font1 swash">A</span>
                            <span className="en-font1 m-en-font1">bout</span>
                        </NavLink>
                        {
                            pathname === '/about' &&
                            <ul>
                                <li>
                                    <NavLink to='/about#begin'>
                                        <h1>BEGINS</h1>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/about#people'>
                                        <h1>PEOPLE</h1>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/about#with'>
                                        <h1>WITH</h1>
                                    </NavLink>
                                </li>
                            </ul>
                        }
                    </li>
                    <li className="subColor1">
                        <NavLink to="/contact">
                            <span className="en-font1 m-en-font1 swash">C</span>
                            <span className="en-font1 m-en-font1">ontact</span>
                        </NavLink>
                    </li>
                </ul>
            </Nav>
        </>
    );
};

const MenuMobile = () => {
    const [isOpen, setIsOpen] = useState(false);

    const {pathname} = useLocation();
    const menus = {
        '/about': (
            <li key="about">
                <NavLink to="/about" onClick={(e) => setIsOpen(!isOpen)}>
                    <span className="swash">A</span>
                    <span>bout</span>
                </NavLink>
            </li>
        ),
        '/contact': (
            <li key="contact">
                <NavLink to="/contact" onClick={(e) => setIsOpen(!isOpen)}>
                    <span className="swash">C</span>
                    <span>ontact</span>
                </NavLink>
            </li>
        ),
        '/work': (
            <li key="work">
                <NavLink to="/work" onClick={(e) => setIsOpen(!isOpen)}>
                    <span>Wor</span>
                    <span className="swash">k</span>
                </NavLink>
                <div css={workIndex} className={pathname === '/work' ? 'show-menu' : 'hide-menu'}>
                    <ToggleIcon/>
                    <WorkIndex/>
                </div>
            </li>
        ),
    };
    return (
        <>
            <Nav>
                <ul>
                    {/* //현재 메뉴 */}
                    {menus[pathname]}
                    {/* isOpen 상태일 때 나오는 메뉴 */}
                    {isOpen &&
                        Object.keys(menus).map((menuName) => {
                            if (menuName !== pathname) return menus[menuName];
                        })}
                </ul>
                <ToggleIcon onClick={(e) => setIsOpen(!isOpen)}>
                    <img src={dropDownImg} alt="dropdown"/>
                </ToggleIcon>
            </Nav>
            {isOpen && <div onClick={(e) => setIsOpen(false)} style={{height: 'calc(100vh - 214px)'}}/>}
        </>
    );
};
