import React, {useEffect, useRef, useState} from 'react';
import {validate} from "react-email-validator";

import Multilingual from "multilingual.js";

import styled from '@emotion/styled';
import {workSetWorks} from "../../reducers";

const Section = styled.div`
  display: flex;
  & > div {
    flex: 1;
  }
  @media (max-width: 768px) {
    display: block;
    & > div {
      padding: 0 1rem;
    }
  }
`;
const ContentContact = styled.div`
  & .textIndent1 {
    text-indent: 1.2em;
  }
  & .paragraph {
    margin-bottom: 2rem !important;
  }
  & .contactList {
    margin-bottom: 1rem !important;
  }
  @media (max-width: 768px) {
    position: absolute;
    bottom: 2rem;
    & > p {
      margin-bottom: .5rem !important;
    }
    & div > .en-font2-d {
      display: inline;
    }
    & .textIndent1 {
      text-indent: 0;
    }
    & .paragraph {
      margin-bottom: .5rem !important;
    }
    & .contactList {
      display: flex;
      margin-bottom: .5rem!important;
    }
    & .contactList p:first-child {
      flex: 1;
    }
    & .contactList p:last-child {
      flex: 2;
    }
  }
`;
const Form = styled.div`
  & form {
    display: flex;
    flex-flow: column;
    padding: 0 2rem;
  }
  order: 2;
  & input {
    margin-bottom: 1rem;
  }
  & input[type='submit'] {
    text-align: right;
    cursor: pointer;
  }
  & input[type='submit']:hover {
    color: #6969aa;
  }
  @media (max-width: 768px) {
    & form {
      padding: 0;
    }
  }
`;

export default function Contact() {
    const [contents, setContents] = useState({addressKr: [], addressEn: [], email: '', instagram: ''});
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [comment, setComment] = useState('');
    const [loaded, setLoaded] = useState(false);
    const testRef = useRef([]);

    useEffect(() => {
        fetch('https://unofficial-office.com/contact-content.json')
            .then(response => response.json())
            .then(data => {
                setContents(data);
            }).then(() => setLoaded(true));
    }, []);

    useEffect(() => {
        if (loaded) {
            let multilingual = new Multilingual({
                containers: testRef.current,
                configuration: ['ko', 'en', 'num', 'punct', {className: 'ml-space', charset: '( )'}
                ]
            });
        }
    }, [loaded]);

    function handleEmChange(event) {
        setEmail(event.target.value);
    }

    function handleChange(event) {
        setSubject(event.target.value);
    }

    function handleCmChange(event) {
        setComment(event.target.value);
    }

    function handleSubmit(event) {
        let url = 'https://hqj4fymao3ses2jwnxgcebikvu0lgmem.lambda-url.ap-northeast-2.on.aws/?';
        if (validate(email)) {
            url += `email=${email}&`;
        } else {
            alert('이메일 주소를 확인해주세요')
            return;
        }
        if (subject) {
            url += `subject=${subject}&`;
        }
        if (comment) {
            url += `comment=${comment}&`;
        }

        fetch(url).then((response) => alert('이메일이 발송됐습니다. 수신되지 않았을 때는 스팸함을 확인해주세요.')).catch((e) => alert('이메일 발송에 실패했습니다. unofficial.hj@gmail.com로 문의 부탁드립니다.'))

        event.preventDefault();
    }

    return (
        <>
            <Section>
                <Form onSubmit={handleSubmit}>
                    <form>
                        <label>
                            <p className="en-font2-d">Email</p>
                            <input type="text" value={email} onChange={handleEmChange}
                                   style={{background: "transparent"}}/>
                        </label>
                        <label>
                            <p className="en-font2-d">Subject</p>
                            <input type="text" value={subject} onChange={handleChange}
                                   style={{background: "transparent"}}/>
                        </label>
                        <label>
                            <p className="en-font2-d">Comment</p>
                            <textarea value={comment} onChange={handleCmChange} cols="30" rows="8"
                                      style={{background: "transparent"}}/>
                        </label>
                        <input type="submit" value="Submit" className="en-font2 m-en-font2 BigCaslonItalic"
                               style={{paddingLeft: 0, paddingRight: 0}}/>
                    </form>
                </Form>
                <ContentContact>
                    <div className="paragraph" id='contact-address'>
                        {
                            contents.addressKr.map((content, index) => (<p className='kr-font2-d' key={index}
                                                                           ref={(elem) => testRef.current.push(elem)}>{content}</p>))
                        }
                        {
                            contents.addressEn.map((content, index) => (<p className='en-font2-d' key={index}
                                                                           ref={(elem) => testRef.current.push(elem)}>{content}</p>))
                        }
                    </div>
                    <div className="contactList">
                        <p className="en-font2-d textIndent1">Mail </p>
                        <p className="en-font2-d"><span className="BigCaslonItalic">
                          <a href={`mailto:${contents.email}`}>{contents.email}</a></span></p>
                    </div>
                    <div className="contactList">
                        <p className="en-font2-d textIndent1">Instagram</p>
                        <p className="en-font2-d"><span className="BigCaslonItalic">
                          <a href={`https://instagram.com/${contents.instagram}`}>@{contents.instagram}</a>
                        </span></p>
                    </div>
                </ContentContact>
            </Section>
        </>
    );
}
