import {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useMediaQuery} from "react-responsive";
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';

import {workSetSwitchTo} from "../../reducers";

const List = styled.li`
  font-size: var(--kr-font2);
  white-space: nowrap;
  position: relative;
  border-top: 2px solid;
  cursor: pointer;
  padding: 0.25rem 0;
  margin-bottom: 0 !important;
  & p {
    display: inline-block;
    width: auto;
    margin-right: 0.5rem;
  }
  &:hover p {
    color: #ff823c;
    animation: flow linear 20s infinite;
  }
  &.active p {
    color: #ff823c;
  }
  @media (max-width: 768px) {
    font-size: 20px;
    border-top: 1px solid;
    ul & {
      padding: .25rem 0 .25rem 1rem;
    }
    &:after {
      content: '';
      display: block;
      width: 1rem;
      height: 100%;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  @keyframes flow {
    from {
      transform: translate(0);
    }
    99%,
    to {
      transform: translate(-100%);
    }
  }
`;
const IndexBtn = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 10;
  background: #f0f0eb;
  font-size: 1.6em;
  left: 0;
  right: 0;
  text-align: center;
  border-top: 1px solid;
  font-style: italic;
  height: 40px;
  & span {
    font-size: unset;
    line-height: 40px;
  }
`;

export default function WorkIndex() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const [open, setOpen] = useState(!isMobile);
    const {work} = useSelector(state => state);

    return (
        <>
            {
                isMobile && open && <div onClick={(e) => setOpen(!open)} style={{height: 'calc(50vh - 40px)'}}/>
            }
            <ul className={open ? 'show' : 'hide'}>
                {
                    work.works && work.works.map((workDetail, index) => {
                        return <List className={work.position === index && 'active'}
                                     key={index}
                                     onClick={(e) => {
                                         if (isMobile) {
                                             const work = document.getElementById(`work-${index}`);
                                             window.scrollTo({top: work.offsetTop - 150, behavior: 'smooth'})
                                             setOpen(!open);
                                         } else {
                                             work.swiper.slideTo(index);
                                         }
                                     }}>
                            <p>
                                {workDetail.titleIndex}
                                {'\u00A0'.repeat(Math.max(3, isMobile ? 58 : 52 - workDetail.titleIndex.length))}
                                {workDetail.titleIndex}
                                {'\u00A0'.repeat(Math.max(3, isMobile ? 58 : 52 - workDetail.titleIndex.length))}
                                {workDetail.titleIndex}
                                {'\u00A0'.repeat(Math.max(3, isMobile ? 58 : 52 - workDetail.titleIndex.length))}
                            </p>
                        </List>
                    })
                }
            </ul>
            {
                isMobile
                && <IndexBtn onClick={(e) => setOpen(!open)} >IND<span className='swash m-en-font4'>E</span>X</IndexBtn>
            }
        </>
    );
}
